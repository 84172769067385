<template>
  <div>
    <v-data-table
      v-model="selectedReport"
      :headers="headers"
      :items="allReportedLeaders"
      fixed-header
      height="550px"
      hide-default-footer
      class="elevation-1"
      :disable-pagination="true"
      :loading="tableLoading"
      loading-text="Loading... Please wait"
      dense
      item-key="index"
      show-select
      :single-select="true"
      :search="search"
    >
      <template v-slot:top>
        <v-divider></v-divider>
        <v-toolbar flat dense>
          <v-toolbar-title>
            <span class="text-h5"> Reported Leaders</span>
          </v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            v-if="selectedReport.length > 0"
            outlined
            color="error"
            @click="updateReport"
            >close</v-btn
          >
          <v-spacer></v-spacer>
          <v-chip class="ma-2" color="green" text-color="white">
            Total
            <v-avatar right class="green darken-4">
              {{ allReportedLeaders.length }}
            </v-avatar>
          </v-chip>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
            style="max-width: 300px"
          ></v-text-field>
          <v-btn dark class="primary" @click="loadReportedLeaders">
            <v-icon left dark> mdi-refresh </v-icon>
            Reload
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";
import { baseURL } from "../constant/constant";
export default {
  name: "Reported Leaders",
  data: () => ({
    search: "",
    tableLoading: false,
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
        width: 10,
      },

      {
        text: "Status",
        value: "status",
      },
      {
        text: "Leader Name",
        value: "leader_name",
      },
      {
        text: "Reported By",
        value: "user_type",
      },
      {
        text: "Comment",
        value: "comment",
      },
    ],
    selectedReport: [],
  }),
  computed: {
    ...mapGetters(["allReportedLeaders", "alertType", "alertMessage"]),
    ...mapState(["response"]),
  },
  mounted() {
    this.loadReportedLeaders();
  },
  methods: {
    ...mapActions(["showAlert"]),
    updateReport() {
      axios
        .put(`${baseURL}admin/update/report`, null, {
          params: {
            report_id: this.selectedReport[0].id,
            status: "Closed",
          },
        })
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: `Updated Successfully!`,
          });
          this.selectedReport = [];
          this.loadReportedLeaders();
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "error",
            message: `${err}`,
          });
        });
    },
  },
};
</script>
